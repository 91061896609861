import React from 'react';
import './About.css';
import me2 from '../images/me2.jpg';

function About() {
  return (
    <div className="page-container">
    <div className="about-container" id="about">
      <div className="atext-container">
        <h4 className="about-text-first">
          <span className="highlighted-word">1.</span> About Me
        </h4>
        <div className="horizontal-line" />
        <p className="about-text-main">
          My interest in coding started at <span className="highlighted-word">age 7</span>, when I did my first Hour of Code and made a music player on Scratch - it was no Spotify, but I loved making it!
        </p>

        <p className="about-text-main">
          Today, I'm an <span className="highlighted-word">incoming SDE Intern @ Amazon</span>,
          have <span className="highlighted-word">published 2 papers @ Caltech</span> in computational astrophysics, <span className="highlighted-word">co-founded</span> my own <span className="highlighted-word">data analytics startup</span>, 
          <span className="highlighted-word"> interned </span> at the <span className="highlighted-word">coding academy</span> where I was once a student, 
           and more.
        </p>

        <p className="about-text-main">
          Beyond software engineering, I love playing <span className="highlighted-word">lacrosse</span> as a <span className="highlighted-word">faceoff specialist</span> for both <span className="highlighted-word">USC</span> and the <span className="highlighted-word">Armenian National/Olympic Teams</span> at the highest level.
        </p>

        <p className="about-text-main">
          Throughout the years, I've been working with <span className="highlighted-word">languages</span> and <span className="highlighted-word">frameworks</span> like:
        </p>
        <div className="lists-container">
          <ul className="list">
            <li>Python</li>
            <li>TypeScript</li>
            <li>JavaScript</li>
          </ul>
          <ul className="list">
            <li>C++</li>
            <li>Java</li>
            <li>SQL</li>
          </ul>
          <ul className="list">
            <li>React.js</li>
            <li>PostgreSQL</li>
            <li>Next.js</li>
          </ul>
        </div>
      </div>
      <div className="image-container">
      <button className="button-56">
        <span className="button-text"></span>
        <div className="image-wrapper">
          <img src={me2} alt="Green Me" className="image-normal" />
          <img src={me2} alt="Me" className="image-hovered" />   
        </div>
      </button>
      </div>
    </div>
    </div>
  );
}

export default About;
