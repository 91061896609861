import React from 'react';
import './Navigation.css';
import logo from '../logo2.png';

function Navigation() {
    const handleLinkedClick = () => {
        window.open('https://www.linkedin.com/in/jakegrigorian/', '_blank');
    };
    const handleGitClick = () => {
        window.open('https://github.com/jgrigorian23', '_blank');
    };
    const handleResearch1Click = () => {
        window.open('https://arxiv.org/pdf/2406.09690', '_blank');
    };
    const handleResearch2Click = () => {
        window.open('https://arxiv.org/pdf/2408.14447', '_blank');
    };
    const handleLacrosseClick = () => {
        window.open('https://asbarez.com/armenias-national-lacrosse-team-stuns-competitors-with-2nd-place-finish-in-greece/', '_blank');
    };
    return (
        <nav className="navigation">
            <a href="/" style={{ textDecoration: 'none' }}>
                <button style={{ marginLeft: '10px', border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}>
                    <img src={logo} alt="Logo" style={{ background: 'transparent', border: 'none' }} />
                </button>
            </a>
            <ol class="horizontal-list">
                 <li><a href="#about">About</a></li>
                 <li><a href="#experience">Experience</a></li>
                 <li><a href="#projects">Projects</a></li>
                 <li><a href="#contact">Contact</a></li>
                 <div>
                    <button onClick={handleResearch1Click} className="buttonicus">
                        Caltech Paper 1
                    </button>
                 </div>
                 <div>
                    <button onClick={handleResearch2Click} className="buttonicus">
                        Caltech Paper 2
                    </button>
                 </div>
                 <div>
                    <button onClick={handleLacrosseClick} className="buttonicus">
                        Armenia Lacrosse
                    </button>
                 </div>
                 <div>
                    <button onClick={handleLinkedClick} className="buttonicus">
                        LinkedIn
                    </button>
                 </div>
                 <div>
                    <button onClick={handleGitClick} className="buttonicus">
                        Github
                    </button>
                 </div>
            </ol>
        </nav>
    );
}

export default Navigation;
